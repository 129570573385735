<template>
  <v-container class="nopadding">
    <v-toolbar fixed
      class="toolbar-noshadow toolbar-page-name">
      <router-link
        v-if="$route.name !== 'contactslist'"
        class="pr-4"
        :to="{ name: 'contactslist' }">
        <v-icon>mdi-arrow-left</v-icon>
      </router-link>
      <v-toolbar-title><strong>{{ pageName }}</strong></v-toolbar-title>
    </v-toolbar>
    <v-container class="page-container">
      <router-view></router-view>
      <v-btn
        v-if="$route.name == 'contactslist'"
        class="add-btn"
        color="primary"
        dark absolute bottom right
        fab
        :to="{ name: 'addcontact'}"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </v-container>
</template>
<script>

  export default {
    name: 'Contacts',
    computed: {
      pageName() {
        let name = 'Contacts'
        switch (this.$route.name) {
          case 'viewcontact':
            name = 'Contact Detail'
            break;
          case 'editcontact':
            name = 'Edit Contact'
            break;
          case 'addcontact':
            name = 'Add Contact'
            break;
          default:
            name = 'Contacts'
            break;
        }
        return name;
      }
    }
  }
</script>
<style lang="scss" scoped>
  .nopadding {
    padding: 0;
    @media screen and (max-width: 768px){
      padding-bottom: 80px;
    }
  }
  .page-container {
    padding-top: 16px;
    padding-bottom: 100px;
    @media screen and (max-width: 768px){
      padding-bottom: 10px;
    }
  }
  .add-btn {
    bottom: 16px!important;
  }
  ::v-deep {
    a{
      text-decoration: none;
      color: inherit;
    }
    @media screen and (max-width: 768px){
      .toolbar-page-name {
        box-shadow: none!important;
        border-bottom: 1px solid lightgray;
      }
    }
  }
</style>
